import React from 'react';
import './Main.css';


const Main = () => {
    return (
        <main className='main'>
            <div className='container_main'></div>

        </main>
    );
};
export default Main