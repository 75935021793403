import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className='footer'>

        </footer>
    );
};
export default Footer