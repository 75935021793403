import React from 'react';
import './App.css';
import './container.css';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

function App() {
    return (

        <div className='container'>
            <div className="Header"> <Header />
                <div className='Main'><Main /></div>
                <div className='footer'><Footer /></div>
            </div>


        </div>);
}
export default App;