import React, { useEffect } from 'react';
import './Header.css';
import logo from './components/image/logo/Logo.png'
import { ReactComponent as Shop } from './components/image/Icon/chop_card.svg'


const Header = () => {
    useEffect(() => {
        const iteam_chop = document.querySelector('.iteam_chop'); const handleTouchStart = () => { iteam_chop.classList.add('hover'); }; const handleTouchEnd = () => { iteam_chop.classList.remove('hover'); }; iteam_chop.addEventListener('touchstart', handleTouchStart); iteam_chop.addEventListener('touchend', handleTouchEnd);
        return () => { iteam_chop.removeEventListener('touchstart', handleTouchStart); iteam_chop.removeEventListener('touchend', handleTouchEnd); };
    }, []); //Код для сенсорного екрану щоб була дія наведення як на ПК

    return (
        <header className="header">
            <div className='iteams'>
                <div className='iteam_logo'><img src={logo} alt="Логотип" width="70" height="70" /></div>
                <div className='iteam_name'><div>digital</div><div>square</div>
                </div>
                <div className='iteam_chop'>
                    <Shop className='shop' />
                </div>
            </div>
        </header >);
};


export default Header